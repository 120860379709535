import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../layouts/layout"
import Header from "../components/paragraphs/header"
import TwoColumnBlock from "../components/paragraphs/two-column-block"
import { buildTwoColumns } from "../utils/helpers"

export default function HowWeWorkWithYou({ data }) {
  const sections = data.sections.relationships.field_sections

  const clientSection = sections[0]
  const clientHeader = clientSection.relationships.field_section_blocks[0]
  let clientTwoColumnSections = [
    clientSection.relationships.field_section_blocks[1],
    clientSection.relationships.field_section_blocks[2],
    clientSection.relationships.field_section_blocks[3],
  ]
  const clientTwoColumns = buildTwoColumns(clientTwoColumnSections)

  const providerSection = sections[1]
  const providerHeader = providerSection.relationships.field_section_blocks[0]
  let providerTwoColumnSections = [
    providerSection.relationships.field_section_blocks[1],
  ]
  const providerTwoColumns = buildTwoColumns(providerTwoColumnSections)

  return (
    <Layout>
      <div className="overflow-hidden bg-gray-50 pb-16 pt-32 md:pt-40 lg:pt-48 lg:pb-12">
        <div className="relative mx-auto max-w-xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
          <svg className="opacity-40 fill-gray-200 absolute -bottom-[18rem] lg:-bottom-[33rem] left-full sm:block w-full lg:w-11/12 transform -translate-x-2/4" viewBox="0 0 312 239">
            <path d="M162.713 222.751L216.32 58.3815C257.999 40.4249 303.174 20.9303 311.085 17.5158L311.999 16.2511L312 16.2506L162.713 222.751ZM107.172 226.654C107.172 226.654 77.059 143.154 76.7899 142.41C76.5879 141.87 72.0789 129.242 85.234 118.205C85.32 118.135 94.721 110.524 104.296 102.928L129.24 157.51C129.246 157.549 129.253 157.587 129.26 157.626L129.289 157.621L160.206 225.275L160.449 225.867C155.292 232.83 148.725 239 133.792 239C120.304 239 112.555 231.96 107.172 226.654ZM105.253 101.147C144.235 70.2672 223.162 8.4806 233.566 0.338028L215.021 57.1994C178.915 72.754 145.628 87.07 141.782 88.6378C131.301 92.9098 119.499 102.054 122.784 121.351C123.853 127.632 125.819 138.678 128.074 151.11L105.326 101.334L105.253 101.147ZM104.102 101.04C94.252 108.845 84.315 116.892 84.216 116.971C70.158 128.768 75.236 142.822 75.289 142.963L104.581 224.19L0.463922 126.318L29.5199 84.4332C38.6429 74.4596 49.932 70.0202 61.059 70.0202C78.203 70.0216 94.959 80.5685 102.78 97.6749L104.24 100.879L104.102 101.04ZM130.778 157.027L160.939 223.027L214.361 59.2252C178.747 74.567 146.192 88.5665 142.385 90.1183C132.449 94.1706 121.255 102.83 124.362 121.082C125.604 128.387 128.059 142.139 130.778 157.027Z" />
          </svg>
          <div className="divide-y-2 divide-gray-200">
            <Header header={clientHeader} />
            <TwoColumnBlock twoColumns={clientTwoColumns} mediaPosition="right" />
          </div>
        </div>
      </div>
      <div className="overflow-hidden bg-white py-16 lg:py-24">
        <div className="relative mx-auto max-w-xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
          <div className="divide-y-2 divide-gray-200">
            <Header header={providerHeader} />
            <TwoColumnBlock twoColumns={providerTwoColumns} />  
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const Head = ({ location, data }) => {
  const canonicalUrl = `${process.env.GATSBY_SITE_URL}how-we-work-with-you/`
  const metaDescription = data.sections?.field_metatags?.description ?? ``
  return (
    <>
      <meta name="description" content={metaDescription} />
      <title>How we work with you | DEC Housing</title>
      <link rel="canonical" href={canonicalUrl} />
    </>
  )
}

export const query = graphql`
  query HowWeWorkWithYouPageQuery {
    sections: nodePage(drupal_internal__nid: {eq: 4}) {
      field_metatags {
        description
      }
      relationships {
        field_sections {
          ... on paragraph__section {
            relationships {
              field_section_blocks {
                ...HeaderFields
                ...TwoColumnBlockFields
              }
            }
          }
        }
      }
      title
    }  
  }
`